.main1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 174px 125px;
    height: 100vh;
    background: none !important;
    color: #002859;
    margin-top: 150px;

    .main-title {
        font-weight: 700;
        font-size: 64px;
        line-height: 150%;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 0;
        color: #002859;
        text-align: center;
    }

    .main-subtitle {
        font-weight: 400;
        font-size: 40px;
        line-height: 150%;
        margin: 0 auto;
        margin-top: 15px;
        max-width: 85%;
        text-align: center;
    }

    .main-descr {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-top: 15px;
        text-align: center;
        padding-top: 10px;

        .mt {
            margin-top: 10px;
        }
    }

    .ant-dropdown-menu-item {
        min-width: 330px !important;
    }
    // .ant-btn-compact-first-item{width: 300px !important;}

    .ant-btn {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        height: auto;
    }
}
